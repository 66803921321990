.underline {
  text-decoration: none; 
  position: relative; 
  letter-spacing: 1px;
}   

.underline:after {
  position: absolute;
  height: 4px;
  margin: 0 auto;
  content: '';
  left: 0;
  right: 0;
  width: 90%;
  background-color: rgba(25, 181, 254, 0.7);
  bottom: -7.5px; /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
}
///////////
.p_row {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  row-gap: 30px;
  column-gap: 30px;
}

.p_column {
  flex: calc(100%/3 - 60px);
  // padding: 20px;
  // margin: 7.5px;
}

@media only screen and (max-width: 885px) {
  .p_column {
    flex: calc(100%/2 - 30px);
  }
  .p_row {
    max-width: 700px;
    margin: auto;
  }
  @media only screen and (max-width: 600px) {
    .p_column {
      flex: 100%;
    }
    .p_row {
      max-width: 300px;
      margin: auto;
    }
  }
}

.showButton {
  background-color: rgb(0, 0, 0, 1);
  color: white;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Verdana, sans-serif;
  border: 10px rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  margin: 20px auto;
}

.p_OSIfontsize {
  font-size: 22px;
  margin-top: 5px;
  text-align: left;
  color: rgba(25, 181, 254, 0.7);
  font-weight: 550;
}

.p_OSItext {
  margin-top: 5px;
  font-family: arial;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
}

.h_linksPL a:link,
.h_linksPL a:visited {
  background-color: rgba(52, 177, 241, 0.7);
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Verdana, sans-serif;
  margin: 10px 10px 10px 10px;
}

.h_linksPL a:hover,
.h_linksPL a:active {
  background-color: rgba(52, 177, 241, 0.6);
}