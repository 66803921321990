// .remainStatic {
//   position: fixed; // fixed
//   width: 100%;
//   background-color: black; //rgba(0,0,0,1);
// }


// .navbar-light .navbar-toggler-icon {
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
// }

// .navbar-light .navbar-toggler {
//   border-color: red;
// }

#cccc {
  background-color: "grey";
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .cccc {
    background-color: "grey";
  }
}