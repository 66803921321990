/* Footer */
.footer {
  text-align: center;
  font-size: 12px;
  letter-spacing: 3px;
}

@media (min-width: 610px) {
  .navbar-expand-custom {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-custom .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .marginAdj {
    margin-top: 40px;
  }
}

@media (max-width: 450px) {
  .f_fontSize {
    font-size: 13px;
  }
}

@media only screen and (max-width: 400px) {
  .footerMessage {
    font-size: 12px;
  }
}