.underline {
  text-decoration: none; 
  position: relative; 
  letter-spacing: 1px;
}   

.underline:after {
  position: absolute;
  height: 4px;
  margin: 0 auto;
  content: '';
  left: 0;
  right: 0;
  width: 90%;
  background-color: rgba(25, 181, 254, 0.7);
  bottom: -7.5px; /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
}

// Copied
* {
  box-sizing: border-box;
}

/* Four Blue square Icons */
.a_row {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.a_column {
  flex: 25%;
  padding: 10px;
}

.a_square {
  width: 100px;
  height: 100px;
  background-color: rgba(25, 181, 254, 0.7);
  text-align: center;
  display: inline-block;
}

.a_OSIfontsize {
  font-size: 22px;
  margin-top: 5px;
}

.a_OSItext {
  margin-top: 5px;
  font-family: times new roman sans serif;
}

@media only screen and (max-width: 800px) {
  .a_column {
    flex: 50%;
  }
  @media only screen and (max-width: 500px) {
    .a_OSIfontsize {
      font-size: 17px;
    }
    .a_containerMargins {
      margin-right: 15px;
      margin-left: 15px;
    }
    .a_OSItext {
      font-size: 14px;
    }
  }
}

/* Photo and Skills */
.a_column2 {
  flex: 50%;
  padding: 10px;
}

.a_imageNumber111 {
  width: 350px;
  height: 275px;
}

.a_rowForSkills {
  display: flex;
}

.a_skillName {
  width: 100px;
  background-color: rgba(25, 181, 254, 0.7);
  text-align: center;
  float: left;
  color: white;
}

.a_skillBar {
  background-color: rgba(222, 221, 221, 0.5);
  flex: 1;
}

.a_percentagesAlign {
  text-align: right;
  margin-right: 5px;
  color: white;
}

@media only screen and (max-width: 800px) {
  .a_column2 {
    flex: 100%;
    margin: auto;
    max-width: 600px;
  }
  .a_marginTopSpacing {
    margin-top: 20px;
  }
  @media only screen and (max-width: 500px) {
    .a_imageNumber111 {
      width: 275px;
      height: 250px;
    }
  }
}