.underline_c {
  text-decoration: none; 
  position: relative; 
  letter-spacing: 1px;
}   

.underline_c:after {
  position: absolute;
  height: 4px;
  margin: 0 auto;
  content: '';
  left: 0;
  right: 0;
  width: 90%;
  background-color: rgba(255, 255, 255, 1);
  bottom: -7.5px; /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
}


// From Old Website

/* CreateContent Function Classes */
.c_containerMargins {
  margin: 40px 40px 60px 40px;
  text-align: center;
}

.c_maxRowWidth {
  max-width: 1000px;
  margin: auto;
}

.c_hrUnderHeading {
  border: 2.5px solid black;
  margin-top: 17.5px auto auto auto;
  width: 325px;
  background-color: black;
}

.c_row {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

@media only screen and (max-width: 650px) {
  .c_containerMargins {
    margin-left: 15px;
    margin-right: 15px;
  }
  .c_hrWidthOnShrink {
    max-width: 280px;
  }
}

/* FirstColumn Function Classes */
.c_column {
  padding: 20px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
  border-width: 0px;
  text-align: center;
}

/* .c_columnBorders {
  border-style: solid;
  border-color: rgba(255, 255, 255, 1);
  border-width: 30px;
  text-align: left;
} */

.c_FCsubTitle {
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
  color: white;
}


@media only screen and (max-width: 650px) {
  .c_column {
    flex: 100%;
    /* padding: 10px; */
  }
  .c_Margin {
    margin-bottom: 20px;
  }
  .c_mmm {
    max-width: 450px;
    text-align: center;
    margin: auto;
  }
}

.SCsubTitle {
  font-size: 15px;
  text-align: center;
  margin-bottom: 25px;
  color: rgba(52, 177, 241, 1);
}

/* Blue Submit Button for Form */
.submitButton {
  background-color: rgba(52, 177, 241, 1);
  color: white;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Verdana, sans-serif;
  border: 10px rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.6);
  transition-duration: 0.3s;
}

.submitButton:hover {
  background-color: rgba(52, 177, 241, 0.7);
  color: white;
}

.submitButton:active {
  background-color: rgba(52, 177, 241, 0.5);
  box-shadow: 0 1px rgba(255, 255, 255, 0.4);
  transform: translateY(2px);
}

.submitButton:focus {
  outline: 0px;
}

.socialMediaSquares {
  height: 50px;
  width: 50px;
  background-color: black;
  margin: 10px;
  /* float: left; */
  display: inline-block;
  /* position: absolute; */
}

.socialMediaSquares:hover {
  background-color: rgba(52, 177, 241, 0.9);
}

@keyframes dropTheIcons {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  49% {
    opacity: 0;
    transform: translateY(16px) scale(1);
  }
  51% {
    opacity: 0;
    transform: translateY(-16px) scale(1);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.socialMediaSquares:hover .fa-github,
.socialMediaSquares:hover .fa-linkedin,
.socialMediaSquares:hover .fa-envelope-o {
  animation-name: dropTheIcons;
  animation-duration: 0.25s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.row00 {
  display: flex;
}

.col11 {
  flex: 1;
  margin-right: 10px;
}



.fadeIT {
  animation-name: fadeSign;
  animation-duration: 5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes fadeSign {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

