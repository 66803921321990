* {
  box-sizing: border-box;
}

.container {
  display: flex;
  // flex-wrap: wrap;
  text-align: center;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.leftContainer {
  flex: 1;
  padding: 0px 10px 0px 50px;
}

.rightContainer {
  flex: 1;
  color: white;
  text-align: center;
  margin: 0px 50px 0px 10px;
}

// .a_imageNumber111 {
//   width: 350px;
//   height: 600px;
// }

.bg {
  background-color: black; // dodgerblue; 
  height: 100vh;
  // background-image: url('../assets/images/nightsky_1.jpg')
}

.line1 {
  font-size: 22px;
  color: white;
}

.lines2and3 {
  font-size: 60px;
  font-weight: bold;
  color: white;
}

.line4 {
  font-size: 30px;
  font-weight: bold;
  color: rgb(47, 174, 233);
}

.lines5and6 {
  font-size: 15px;
  color: rgba(255,255,255,0.6);
}

/* Blue Submit Button for Form */
.fadeInButton {
  // Keyframe name, duration, timing-function, delay, iteration-count, direction
  animation: fadeIn 6.5s linear 0s 1 forwards;
}

@keyframes fadeIn {
  0%, 75% { 
    opacity: 0;
    visibility: visible;
  }
  100% { 
    opacity: 1; 
    visibility: visible;
  }
}

.contactMe:link, .contactMe:visited {
  background-color: rgb(47, 174, 233);
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 25px;
  border-radius: 25px;
  visibility: hidden;
}

.contactMe:hover, .contactMe:active {
  background-color: rgba(47, 174, 233, 0.8);
}


// Three Blue Buttons
.centerThreeBlueButtons {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-right: -45%;
}

.threeBlueButtons a:link,
.threeBlueButtons a:visited {
  background-color: rgba(25, 181, 254, 0.9);
  color: white;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Verdana, sans-serif;
  letter-spacing: 1px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  visibility: hidden;
}

.threeBlueButtons a:hover,
.threeBlueButtons a:active {
  background-color: rgba(25, 181, 254, 0.7);
}


.contactMe2:link, .contactMe2:visited {
  background-color: rgb(47, 174, 233);
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 25px;
  border-radius: 0px;
  visibility: hidden;
  width:110px;
}

.contactMe2:hover, .contactMe2:active {
  background-color: rgba(47, 174, 233, 0.8);
}